import ModifyBookingTime from './modify-booking-time'
import ModifyBookingStylist from './modify-booking-stylist'
import ModifyBookingRemark from './modify-booking-remark'
import BookingCustomer from './booking-customer'
import Assistance from 'components/common/booking-assistances/panel'
import ProductPanel from 'components/pages/studio-manage-id/product/panel/studio-product-create'
import { Context, useStore } from 'store/panel-stylist-booking'

export default function StylistBooking() {
  const store = useStore()
  const panel = usePanel()

  return (
    <Context.Provider value={ store }>
      <C.Modal
        visible={ panel.visible('stylistBooking') }
        close={ () => panel.close('stylistBooking') }
      >
        {!!store.booking && (
          <Body { ...store } />
        )}
      </C.Modal>
      <ModifyBookingTime />
      <ModifyBookingStylist />
      <ModifyBookingRemark />
      <BookingCustomer />
      <Assistance />
      <ProductPanel />
    </Context.Provider>
  )
}

const Body = ({
  booking,
  checkout,
  cancel,
  cancelPenalty,
  modifyBookingTime,
  modifyBookingStylist,
  modifyBookingRemark,
  bookingCustomer
}) => {

  const {
    id,
    $details,
    $serviceBlock,
    $productBlock,
    discount,
    customer,
    stylist,
    checkoutTime,
    payByMapPay,
    notes,
    realPayment,
    paymentMethod
  } = booking

  const {
    serviceTime,
    gender,
    phone,
    stateColor,
    i18nState,
    disabled,
    amount,
    alreadyPaid,
    penaltyAmount,
    showTechnicalCancel,
    isNoShow,
    isCancelled,
    finalAmount,
    checkoutBtnText,
    cancelBtnText,
    noteSmallImageUrls,
    noteLargeImageUrls
  } = $details

  const checkoutView = checkoutTime ? <Row title='結帳時間'>{checkoutTime.ymdhm()}</Row> : ''
  const toStylist = () => {
    h.path.go.stylist({ id: stylist.user.id })
  }
  const openAssistance = () => h.openBookingAssistanceDetail(id)

  const renderBtns = () => {
    if (penaltyAmount > 0 || isNoShow) return (
      <div className='sm:flex border-t text-sm items-center justify-end px-1'>
        <div className='flex space-x-6 justify-center pt-4 sm:max-w-[12rem] sm:mr-6'>
          <div>小計 {amount}</div>
          <div>已付 {alreadyPaid}</div>
          <div className='text-red-500 font-bold'>違約金 {penaltyAmount}</div>
        </div>
        <div className='flex flex-1 shrink-0 justify-center pt-4'>
          <C.Button uid='stylistBooking.cancelPenalty' className='flex-1'
            onClick={ cancelPenalty }>取消違約</C.Button>
        </div>
      </div>
    )

    if (payByMapPay) return (
      <div className='flex justify-between pt-4 border-t'>
        <div className='self-center shrink-0'>
          <C.Button uid='stylistBooking.checkout' variant='outlined' className='flex-1'
            onClick={ openAssistance }>互助登錄
          </C.Button>
        </div>
        <div>
          <div className='font-bold px-4'>已付 {realPayment.currency()}</div>
          <div className='text-gray-400 px-4'>付款方式: {paymentMethod}</div>
        </div>
      </div>
    )

    if (isCancelled) return (
      <div className='sm:flex border-t text-sm items-center justify-center px-1'>
        <div className='flex space-x-6 justify-center pt-4'>
          <div>小計 {amount}</div>
          <div>已付 {alreadyPaid}</div>
          <div className='text-red-500 font-bold'>轉儲值金 {alreadyPaid}</div>
        </div>
      </div>
    )

    if (showTechnicalCancel) return (
      <div className='flex items-center justify-between pt-4 border-t'>
        <div className='font-bold px-4'>總計 {realPayment.currency()}</div>
        <div className='self-center shrink-0'>
          <C.Button uid='stylistBooking.cancel' variant='outlined' className='flex-1'
            onClick={ cancel }>{cancelBtnText}
          </C.Button>
        </div>
      </div>
    )

    return (
      <div className='sm:flex border-t text-sm items-center justify-end px-1'>
        <div className='flex space-x-6 justify-center pt-4 sm:max-w-[12rem] sm:mr-6'>
          <div>小計 {amount}</div>
          <div>已付 {alreadyPaid}</div>
          <div className='text-red-500 font-bold'>應付 {finalAmount}</div>
        </div>
        <div className='flex flex-1 shrink-0 space-x-3 justify-center pt-4'>
          <C.Button uid='stylistBooking.checkout' className='flex-1'
            onClick={ checkout }>{checkoutBtnText}</C.Button>
          <C.Button uid='stylistBooking.cancel' variant='outlined' className='flex-1'
            onClick={ cancel }>{cancelBtnText}</C.Button>
        </div>
      </div>
    )
  }

  return (
    <div className='sm:w-[28rem] flex flex-col'>
      <div className='text-center py-1'>預約明細</div>
      <div className='flex-1 overflow-scroll'>
        <div className='py-3 space-y-3 border-b'>
          <Row title='預約時間'>
            {serviceTime}
            <span className={ cn('text-sm text-business cursor-pointer ml-2', { hidden: payByMapPay }) }
              onClick={ modifyBookingTime }
            >修改時間</span>
          </Row>
          {checkoutView}
          <Row title='狀態'>
            <span className={ stateColor }>{i18nState}</span>
          </Row>
          <Row title='設計師'>
            <div className='cursor-pointer' onClick={ toStylist }>{stylist.user.name}</div>
            <span className={ cn('text-sm text-business cursor-pointer ml-2', { hidden: payByMapPay || !stylist.studio }) }
              onClick={ modifyBookingStylist }
            >更換設計師</span>
          </Row>
          <Row title='顧客'>
            <span className='underline cursor-pointer' onClick={ bookingCustomer }>{customer.name}</span>
            <span className='pl-2 text-sm'>({gender}) (電話: {phone})</span>
          </Row>
          {notes && (
            <Row title='備註'>
              <div className='flex-1'>
                <div className='flex items-center'>
                  <div { ...notes.rawHtml() } />
                  <div className='text-sm text-business cursor-pointer ml-2 min-w-[2rem]'
                    onClick={ modifyBookingRemark }
                  >修改備註</div>
                </div>

                {noteSmallImageUrls &&
                  <div className='flex flex-wrap pt-4'>
                    {noteSmallImageUrls.map((url, index) => (
                      <div className='w-1/5 p-[2px] cursor-pointer' key={ index }
                        onClick={ () => { h.openGallery(noteLargeImageUrls, index) } }>
                        <C.Image
                          src={ url }
                          className='pt-[100%]'
                          alt='備註圖片'
                        />
                      </div>
                    ))}
                  </div>
                }
              </div>
            </Row>
          )}
          {discount && (
            <Row title='優惠券'>
              <Discount
                title={ discount.$title }
                originalRange={ discount.$originalRange }
                remark={ discount.$remark }
                range={ discount.$range }
                description={ discount.description }
              />

            </Row>
          )}

        </div>
        <div className='pt-5'>
          <ServiceBlock
            disabled={ disabled }
            { ...$serviceBlock }
          />
        </div>
        <div className='pt-3 pb-5'>
          <ProductBlock
            disabled={ disabled }
            { ...$productBlock }
          />
        </div>
      </div>
      {renderBtns()}
    </div>
  )
}

const Row = ({ title, children }) => (
  <div className='flex items-start'>
    <div className='text-gray-500 w-[6rem] font-medium'>{title}</div>
    <div className='flex items-center flex-1'>
      {children}
    </div>
  </div>
)

const Discount = ({ title, originalRange, remark, range, description }) => (
  <div className='py-2 px-3 border mb-3 shadow'>
    <div className='flex items-center justify-between space-x-3'>
      <div className='space-y-1'>
        <div className='font-bold line-clamp-1'>{title}</div>
        <div className='text-gray-400 line-through text-sm line-clamp-1'>{originalRange}</div>
        {remark &&
          <div className='text-gray-500 text-xs' { ...remark.rawHtml() } />
        }
      </div>
      <div className='flex-1 px-1'></div>
      <div className='shrink-0 text-red-500'>{range}</div>
    </div>
    <div className='pt-3 text-sm text-gray-600' { ...description.rawHtml() } />
  </div>
)

const ServiceBlock = ({
  disabled,
  services,
  servicePriceChange,
  deleteService,
  serviceLoadMore,
  serviceOptions,
  addService
}) => (
  <div>
    {services.map(current => (
      <div key={ current.key } className='flex items-center space-x-1 py-1'>
        <div className='flex-1'>{current.name}</div>
        <div>$</div>
        <_Input
          disabled={ disabled }
          className='w-24'
          value={ current.price }
          onChange={ price => servicePriceChange(current, price) }
          variant='standard'
        />
        {!disabled &&
          <div className='cursor-pointer scale-75'
            onClick={ () => deleteService(current) }
          >
            <Icon.Close />
          </div>
        }
      </div>
    ))}
    {!disabled &&
      <C.AsyncSelect
        className='flex-1 border-t border-gray-400 mt-6 text-gray-400 p-2'
        label='新增服務項目'
        onChange={ addService }
        optionFormat={ serviceOptions }
        optionRender={ option => (
          <div className='overflow-hidden'>
            <div className='truncate ml-2'>{option.value}</div>
          </div>
        ) }
        loadMore={ serviceLoadMore }
      />
    }
  </div>
)

const ProductBlock = ({
  disabled,
  products,
  productCountChange,
  productPriceChange,
  deleteProduct,
  productLoadMore,
  productOptions,
  addProduct,
  newProductName,
  openProductCreate
}) => (
  <div>
    {products.map(current => (
      <div key={ current.key } className='flex space-x-1 py-1'>
        <div className='flex-1 truncate'>{current.name}</div>
        <_Input
          disabled={ disabled }
          className='w-14'
          value={ current.count }
          onChange={ count => productCountChange(current, count) }
          variant='standard'
        />
        <div className='px-2'>x</div>
        <div>$</div>
        <_Input
          disabled={ disabled }
          className='w-24'
          value={ current.price }
          onChange={ price => productPriceChange(current, price) }
          variant='standard'
        />
        {!disabled &&
          <div
            className='cursor-pointer scale-75'
            onClick={ () => deleteProduct(current) }
          >
            <Icon.Close />
          </div>
        }
      </div>
    ))}
    {!disabled &&
      <C.AsyncSelect
        className='flex-1 border-t border-gray-400 mt-6 text-gray-400 py-2'
        label='新增店販商品'
        onChange={ addProduct }
        optionFormat={ productOptions }
        optionRender={ option => (
          <div className='flex items-center justify-center overflow-hidden'>
            <div className='mx-3'>
              <C.Image className='w-10 h-10' src={ option.url } alt='logo' />
            </div>
            <div className='ml-2'>
              <div className='truncate whitespace-pre-wrap'>{option.value}</div>
              <div className='text-gray-500 text-sm truncate'> {option.brandDesc} </div>
              <div className='text-gray-500 text-sm truncate'> {option.priceDesc} </div>
            </div>
          </div>
        ) }
        loadMore={ productLoadMore }
        createValueLabel='建立產品'
        defaultKeyword={ newProductName }
        openCreateValue={ openProductCreate }
      />
    }
  </div>
)

const _Input = ({ className, ...props }) => <div className={ cn('mb-[-1.7rem]', className) }><C.Input center { ...props } /></div>
