//import useFacebookLogin from '../action/facebook-login'
import useGoogleLogin from '../action/google-login'

export default function factoryLogin({ login }) {
  return function useStepLogin() {
    useGoogleLogin({ login })
    const [mode, setMode] = useState('phone')
    const { phoneCodes } = usePhoneCode()
    const phoneForm = useForm({
      phoneCode: { value: '886TW' },
      phone: { rule: 'required' }
    })

    const emailForm = useForm({
      email: { rule: 'required|email' },
      password: { rule: 'required' }
    })

    const toggleMode = () => {
      const nextMode = mode === 'phone' ? 'email' : 'phone'
      setMode(nextMode)
    }
    //const facebookLogin = useFacebookLogin({ login })
    const phoneLogin = () => login({
      type: 'phone',
      phoneCode: phoneForm.phoneCode.value,
      phone: phoneForm.phone.value
    })

    const emailLogin = () => login({
      type: 'email',
      key: emailForm.email.value,
      password: emailForm.password.value
    })

    const form = mode === 'phone' ? phoneForm : emailForm
    const formLogin = mode === 'phone' ? phoneLogin : emailLogin

    return {
      mode,
      toggleMode,
      phoneCodes,
      //facebookLogin,
      form,
      formLogin
    }
  }
}
