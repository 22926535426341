const template = `
  mutation bookingTechnicalSplit($input: BookingTechnicalSplitInput!) {
    bookingTechnicalSplit(input: $input)
  }
`

export default async function bookingTechnicalSplit(bookingId) {
  const input = { bookingId }
  await request(template, { input })
}
