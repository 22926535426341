const template = `
  mutation bookingTechnicalCancel($input: BookingTechnicalCancelInput!) {
    bookingTechnicalCancel(input: $input)
  }
`

export default async function bookingTechnicalCancel(bookingId) {
  const input = { bookingId }
  await request(template, { input })
}
