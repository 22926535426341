const template = `
  query Booking($id: ID!){
    booking(id: $id){
      id
      name
      gender
      phone
      startTime
      endTime
      realPayment
      minPayment
      source
      state
      showQRCode
      payByMapPay
      prepayAmount
      mapPayRewards
      prepayDeposit
      hairmapId
      usedReward
      usedDeposit
      createdAt
      notes
      passersbyCategory
      remainDeposit
      remainReward
      maxRewardUseRate
      isCommented
      paymentMethod
      isTechnicalAssistance
      assistanceAt
      stylist{
        id
        user{
          id
          avatar
          name
        }
        studio{
          id
          name
          address
        }
        mapPaySupported
      }
      customer{
        id
        name
        phone
        gender
      }
      discount{
        id
        name
        originalMinPrice
        originalMaxPrice
        discountType
        value
        limitDay
        limitTimeSlot
        limitCustomer
        type
        deadline
        description
        campaign{
          name
        }
      }
      services{
        minPrice
        maxPrice
        finalPrice
        service{
          id
          name
        }
      }
      products{
        product
        name
        finalPrice
        quantity
        cost
        hairBrand{
          name
          url
        }
      }
      user{
        id
        avatar
      }
      noteImages{
        photo{
          large {
            url
          }
          small {
            url
          }
        }
      }
    }
  }
`

export default async function booking(bookingId) {
  const { booking } = await request(template, { id: bookingId })
  return booking
}
